import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material'
import NotificationProvider from './providers/notification'
import ErrorProvider from './providers/error'
import Styles from './assets/variables.scss'
import AuthProvider from './providers/auth'
import { InstallCard } from './components'
import Router from './router'
import './assets/index.scss'

const theme = createTheme({
  palette: {
    info: { main: Styles.infoColor },
    error: { main: Styles.errorColor },
    warning: { main: Styles.warningColor },
    success: { main: Styles.successColor },
    primary: { main: Styles.primaryColor },
    secondary: { main: Styles.secondaryColor },
    background: { default: Styles.backgroundColor },
  },
})

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorProvider>
        <AuthProvider>
          {/* <InstallCard /> TODO add this later on */}
          <NotificationProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </NotificationProvider>
        </AuthProvider>
      </ErrorProvider>
    </ThemeProvider>
  )
}

export default App
